import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs, H5, H4, LI, P, UL } from '../../../AbstractElements';
import CountUp from 'react-countup';
import DataTable from 'react-data-table-component'
import { UserMinus, UserPlus } from 'react-feather';
import { User, Clipboard, Trash} from 'react-feather';
import { Container, Row, Col, Card, CardHeader,CardBody,Form,Button } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import { useNavigate,useLocation } from "react-router-dom";
import {postRequest,getRequest,deleteRequest,putRequest} from "../../../Services/http-request-service.js"
import { TotalUsers, WeeklyMonDropdown } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';
  
const ViewPatient = (props) => {

  // Constants
  const { pathname } = useLocation();
  const navigate = useNavigate(); 
  const PATIENTNAME = getPatientName();

  // Table Data Constants
  const tableColumns = [
  {
    name: 'Report Number',
    selector: row => row.id,
    filterable: true,
  },
  {
    name: 'Name',
    selector: row => row.name,
    filterable: true,
  },  
  {
    name: 'Date',
    selector: row => row.date,
    filterable: true,
  },
  {
    name: 'View',
    selector: row => row.view,
    filterable: true,
    cell:(row) => <User onClick={() => {viewReport(row.name);}}/>,
  },
  {
    name: 'Delete',
    selector: row => row.delete,
    filterable: true,
    cell:(row) => <Trash onClick={() => {deleteReport(row.name);}}/>,
  }
];


  const datafortables = [
  {
    id: '1',
    name: 'Report 1',
    date: '01/02/2023',
    view: "",
    delete: "",
  },
  {
    id: '2',
    name: 'Report 2',
    date: '01/02/2023',
    view: "",
    delete: "",
  },
  {
    id: '3',
    name: 'Report 3',
    date: '01/02/2023',
    view: "",
    delete: "",
  }
];

  // Global Variables

  // useState Constants

  // stats
  const [reportsCount, setReportsCount] = useState(0);

  // Data Tables
  const [tableData, setTableData] = useState([]);

  // Functions

  // onload
  useEffect(() => {
    getReports();
  }, []);

  function getPatientName()
  { 
    let data = pathname.replace(`${process.env.PUBLIC_URL}`,"");
    data = data.split("/");
    return data[3];
  }

  function generateReport()
  {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/generateReport/${PATIENTNAME}/Dubai`);
  }

  function compareReport()
  {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/compareReport/${PATIENTNAME}/Dubai`);
  }

  function viewReport(name)
  {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/reports/${PATIENTNAME}/${name}`);
  }

  async function deleteReport(name)
  {
    // Constants
    const URL = "http://localhost:3000/v1/internal/authorized/patients/reports/delete";

    // Variables
    let data = {};

    // ask to confirm to delete the patient
    let userData = await SweetAlert.fire({ title: "Warning!", text: `Are you sure you want to delete report ${name} ?`,showCancelButton: true,reverseButtons: true, icon: "warning",confirmButtonText: 'Yes, delete it!',cancelButtonText: 'No, cancel!', });

    if (!userData.isConfirmed)
    {
      return;
    }

    // build the request data
    data = {"name":name};

    // send the request
    try
    {
      setTableData([]);
      let result = await deleteRequest(URL,data);
      setTableData(result);
    }
    catch (error)
    {
      setTableData([]);
      SweetAlert.fire({ title: "Error!", text: "Could not delete the report", icon: "error" });
      return;
    }
  }

  async function getReports()
  {
    // Constants
    const URL = "http://localhost:3000/v1/internal/authorized/patients/reports/get";

    // send the request
    try
    {
      setTableData(datafortables);
      let result = await getRequest(URL);
      setTableData(result);
    }
    catch (error)
    {
      setTableData(datafortables);
      SweetAlert.fire({ title: "Error!", text: "Could not get the reports list", icon: "error" });
      return;
    }
  }
  

  // HTML
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Instructions" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
        
            <Col sm="12">
            <Card>
      <CardHeader className='card-no-border pb-0'>
        <div className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={WeeklyMonDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
        <UL attrUL={{ className: 'user-list' }}>
          <LI>
            <div className='user-icon primary'>
              <div className='user-box'>
                <UserPlus className='font-primary' />
              </div>
            </div>
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>Diagnosis</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>Essential Hypertension</span>
              </span>
            </div>
          </LI>
          <LI>
            <div className='user-icon success'>
              <div className='user-box'>
                <UserMinus className='font-success' />
              </div>
            </div>
            <div>
            <H5 attrH5={{ className: 'mb-1' }}>Pill Strength</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>20 mg tablets</span>
              </span>
            </div>
          </LI>
        </UL>
      </CardBody>
    </Card>



    <Card>
      <CardHeader className='card-no-border pb-0'>
        <div className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={WeeklyMonDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
        <UL attrUL={{ className: 'user-list' }}>
          <LI>
            <div className='user-icon primary'>
              <div className='user-box'>
                <UserPlus className='font-primary' />
              </div>
            </div>
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>Pill Frequency</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>2 per day</span>
              </span>
            </div>
          </LI>
          <LI>
            <div className='user-icon success'>
              <div className='user-box'>
                <UserMinus className='font-success' />
              </div>
            </div>
            <div>
            <H5 attrH5={{ className: 'mb-1' }}>Pill Instructions</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>Without food</span>
              </span>
            </div>
          </LI>
        </UL>
      </CardBody>
    </Card>




    <Card>
      <CardHeader className='card-no-border pb-0'>
        <div align="center" className='header-top'>
          <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'end' }} options={WeeklyMonDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
        </div>
      </CardHeader>
      <CardBody className='py-lg-3'>
      <UL attrUL={{ className: 'user-list' }}>
          <LI>
            <div className='user-icon primary'>
              <div className='user-box'>
                <UserPlus className='font-primary' />
              </div>
            </div>
            <div>
              <H5 attrH5={{ className: 'mb-1' }}>Reason Taking</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>DATA</span>
              </span>
            </div>
          </LI>
          <LI>
            <div className='user-icon success'>
              <div className='user-box'>
                <UserMinus className='font-success' />
              </div>
            </div>
            <div>
            <H5 attrH5={{ className: 'mb-1' }}>Side Effects</H5>
              <span className='font-primary d-flex align-items-center'>
              <span className='f-w-500'>DATA</span>
              </span>
            </div>
          </LI>
        </UL>
      </CardBody>
    </Card>
    
              
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default ViewPatient;