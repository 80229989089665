import { Home } from 'react-feather'
export const MENUITEMS = [
    {
        menutitle: "General",
        menucontent: "Dashboards",
        Items: [           
            { path: `${process.env.PUBLIC_URL}/dashboard/default`, icon: Home, title: 'Main Menu', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/dashboard/default/viewPatient/name`, icon: Home, title: 'Instructions', type: 'link' }
        ]
    },
]