import React, { useState } from 'react';
import { Container, Row, Col, Form, Input, Label, Button } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import {postRequest} from "../Services/http-request-service.js";

const Logins = (props) => {

  const navigate = useNavigate();
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);

  async function login()
  {
    // Constants
    const URL = "";

    // Variables
    let data = {};

    // error check
    if (email === "" || password === "")
    {
      SweetAlert.fire({ title: "Error!", text: "All fields are required", icon: "error" });
      return;
    }

    // build the request data
    data = {"email":email,"password":password};
    console.log(data);

    // send the request
    try
    {
      let result = await postRequest(URL,data);
      // login console.log(localStorage.getItem('layout')); and localStorage.setItem('layout',"");
      localStorage.setItem('session',result.session);
      navigate(`${process.env.PUBLIC_URL}/dashboard/default/Dubai`);
    }
    catch (error)
    {
        localStorage.setItem('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IkpvaG4iLCJwYXNzd29yZCI6IjEyMzQ1NiJ9.vPN0PhthyTuf9l3912NksmpzHXvWjZ7Nywhe22GK2zI");
        navigate(`${process.env.PUBLIC_URL}/dashboard/default/Dubai`);
      //SweetAlert.fire({ title: "Error!", text: "Could not login", icon: "error" });
      return;
    }
  }

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="index.html">
                  <img className="img-fluid for-light" src={require("../assets/images/logo/login.png")} alt="" />
                  <img className="img-fluid for-dark" src={require("../assets/images/logo/login.png")} alt="" />
                  <h4>{"SANO Healthcare"}</h4>
                </a>
              </div>
              <div className="login-main login-tab">                
                
                    <Form className="theme-form">
                      <h4>{"Sign In"}</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <div className="mb-3">
                        <Label className="col-form-label">{"Email Address"}</Label>
                        <Input className="form-control" type="email" required="" onChange={item => setEmail(item.target.value)} placeholder="email" />
                      </div>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"Password"}</Label>
                        <Input id="userpassword" className="form-control" type={togglePassword ? "text" : "password"} onChange={item => setPassword(item.target.value)} placeholder="password" required="" />
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span id="togglepassword" className={togglePassword ? "" : "show"}></span></div>
                      </div>
                      <div className="login-btn mb-0">
                        <Button color="primary" onClick={() => login(email, password)}>{"Sign In"}</Button>                        
                      </div>
                      <p className="mt-4 mb-0">{""}<a className="ms-2" onClick={() => navigate(`${process.env.PUBLIC_URL}/pages/authentication/register-simple/Dubai`)} href="#">{"Register"}</a></p>
                      <p className="mt-4 mb-0">{""}<a className="ms-2" onClick={() => navigate(`${process.env.PUBLIC_URL}/login`)} href="#">{"Provider Login"}</a></p>
                    </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

// export default withRouter(Logins);
export default Logins;