import React, { Fragment } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { BuyNow, CheckFeatures, Document, QuickOption, Support } from '../../Constant';

const NavCustomizer = ({ callbackNav, selected }) => {
  return (
    <Fragment>
      <Nav className='flex-column nac-pills' id='c-pills-tab' role='tablist' aria-orientation='vertical'>
        
      </Nav>
    </Fragment>
  );
};

export default NavCustomizer;
