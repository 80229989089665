import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs, H4, LI, P, UL } from '../../../AbstractElements';
import DataTable from 'react-data-table-component'
import { Clipboard} from 'react-feather';
import { Container, Row, Col, Card, CardHeader,CardBody,Form,Button } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import { useNavigate,useLocation } from "react-router-dom";
import {postRequest,getRequest,deleteRequest,putRequest} from "../../../Services/http-request-service.js"
  
const CompareReport = (props) => {

  // Constants
  const { pathname } = useLocation();
  const navigate = useNavigate(); 
  const PATIENTNAME = getPatientName();

  // Table Data Constants
  const tableColumns = [
  {
    name: 'Report Number',
    selector: row => row.id,
    filterable: true,
  },
  {
    name: 'Name',
    selector: row => row.name,
    filterable: true,
  },  
  {
    name: 'Date',
    selector: row => row.date,
    filterable: true,
  },
  {
    name: 'Slot 1',
    selector: row => row.view,
    filterable: true,
    cell:(row) => <Clipboard onClick={() => {addSlot(row.name,1);}}/>,
  },
  {
    name: 'Slot 2',
    selector: row => row.delete,
    filterable: true,
    cell:(row) => <Clipboard onClick={() => {addSlot(row.name,2);}}/>,
  }
];


  const datafortables = [
  {
    id: '1',
    name: 'Report 1',
    date: '01/02/2023',
    slot1: "",
    slot2: "",
  },
  {
    id: '2',
    name: 'Report 2',
    date: '01/02/2023',
    slot1: "",
    slot2: "",
  },
  {
    id: '3',
    name: 'Report 3',
    date: '01/02/2023',
    slot1: "",
    slot2: "",
  }
];

const tableColumns2 = [
  {
    name: 'Item Number',
    selector: row => row.id,
    filterable: true,
  },
  {
    name: 'Item',
    selector: row => row.item,
    filterable: true,
  },
  {
    name: 'Slot 1 Value',
    selector: row => row.slot1,
    filterable: true,
  },
  {
    name: 'Slot 2 Value',
    selector: row => row.slot2,
    filterable: true,
  }
];


  const datafortables2 = [
  {
    id: '1',
    item: 'Sodium',
    slot1: "5",
    slot2: "6",
  },
  {
    id: '2',
    item: 'Sodium',
    slot1: "5",
    slot2: "6",
  },
  {
    id: '3',
    item: 'Sodium',
    slot1: "5",
    slot2: "6",
  }
];

  // Global Variables

  // useState Constants
  const [slot1, setSlot1] = useState("");
  const [slot2, setSlot2] = useState("");

  // Data Tables
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  // Functions

  // onload
  useEffect(() => {
    setSlot1("Slot1 - ");
    setSlot2("Slot2 - ");
    getReports();
  }, []);

  function getPatientName()
  { 
    let data = pathname.replace(`${process.env.PUBLIC_URL}`,"");
    data = data.split("/");
    return data[3];
  }

  function viewPatient()
  {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/viewPatient/${PATIENTNAME}/Dubai`);
  }

  async function addSlot(name,slot)
  {
    if (slot1 === `Slot1 - ${name}` || slot2 === `Slot2 - ${name}`)
    {
      SweetAlert.fire({ title: "Error!", text: "Can not add a report that is already in a current slot", icon: "error" });
      return;
    }
    slot === 1 ? setSlot1(`Slot1 - ${name}`) : setSlot2(`Slot2 - ${name}`);
  }

  async function compareReportData()
  {
    // Constants
    const URL = "http://localhost:3000/v1/internal/authorized/patients/reports/compare/compare";

    // Variables
    let data = {};

    // error check
    if (slot1 === "Slot1 - " || slot2 === "Slot2 - ")
    {
      SweetAlert.fire({ title: "Error!", text: "Can not compare reports, as their is a missing slot", icon: "error" });
      return;
    }

    // build the request data
    data = {"slot1":slot1.substring(8),"slot2":slot2.substring(8)};

    // send the request
    try
    {
      setTableData2(datafortables2);
      let result = await postRequest(URL,data);
      setTableData2(result);
    }
    catch (error)
    {
      setTableData2(datafortables2);
      SweetAlert.fire({ title: "Error!", text: "Could not compare the report data", icon: "error" });
      return;
    }
  }

  async function getReports()
  {
    // Constants
    const URL = "http://localhost:3000/v1/internal/authorized/patients/reports/compare/get";

    // Variables
    let data = {};

    // build the post request data
    data = {"name":PATIENTNAME};

    // send the post request
    try
    {
      setTableData(datafortables);
      let result = await postRequest(URL,data);
      setTableData(result);
    }
    catch (error)
    {
      setTableData(datafortables);
      SweetAlert.fire({ title: "Error!", text: "Could not get the reports list", icon: "error" });
      return;
    }
  }

  // HTML
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Compare Reports View" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="6" xl="6" lg="6">
              <Card className="o-hidden">
                <CardBody className="bg-primary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center"><Clipboard /></div>
                    <div className="media-body"><h3 className="m-0">{slot1}</h3>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" xl="6" lg="6">
              <Card className="o-hidden">
                <CardBody className="bg-primary b-r-4">
                  <div className="media static-top-widget">
                    <div className="align-self-center text-center"><Clipboard /></div>
                    <div className="media-body"><h3 className="m-0">{slot2}</h3>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                                <CardHeader>
                                    <h5>{"Reports"}</h5>
                                </CardHeader>
                                <CardBody>


                                <Button color="primary" onClick={viewPatient}>{"Back To View Patient"}</Button><br></br><br></br>
                                <Button color="success" onClick={compareReportData}>{"Compare Report Data"}</Button>



                                    <div id="the-basics">
                                        <Form>
                                            <div><br></br>
                                            <DataTable
                  data={tableData}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  Clicked
                  pagination
                  paginationComponentOptions={{selectAllRowsItem: true,selectAllRowsItemText: "ALL"}}                  
                />

<br></br>
<h5>{"Compare Report Data Results"}</h5>
<DataTable
                  data={tableData2}
                  columns={tableColumns2}
                  striped={true}
                  center={true}
                  Clicked
                  pagination
                  paginationComponentOptions={{selectAllRowsItem: true,selectAllRowsItemText: "ALL"}}                  
                />

                      </div>
                 
                                          
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default CompareReport;