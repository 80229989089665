export async function postRequest (url,objectdata)
{
  // Constants
  const HEADERS = {'Content-Type': 'application/json;charset=utf-8','Accept': 'application/json'};
  const METHOD = 'POST';

  // Variables
  var response;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS,
      body: JSON.stringify(objectdata)
    });

    console.log(JSON.stringify(objectdata));

    return response.ok ? response.json() : Promise.reject(response);
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}

export async function deleteRequest (url,objectdata)
{
  // Constants
  const HEADERS = {'Content-Type': 'application/json;charset=utf-8','Accept': 'application/json'};
  const METHOD = 'DELETE';

  // Variables
  var response;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS,
      body: JSON.stringify(objectdata)
    });

    return response.ok ? response.json() : Promise.reject(response);
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}

export async function putRequest (url,objectdata)
{
  // Constants
  const HEADERS = {'Content-Type': 'application/json;charset=utf-8','Accept': 'application/json'};
  const METHOD = 'PUT';

  // Variables
  var response;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS,
      body: JSON.stringify(objectdata)
    });

    return response.ok ? response.json() : Promise.reject(response);
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}

export async function getRequest (url)
{
  // Constants
  const HEADERS = {'Accept': 'application/json'};
  const METHOD = 'GET';

  // Variables
  var response;

  try
  {
    response = await fetch(url, {
      method: METHOD,
      headers: HEADERS
    });

    return response.ok ? response.json() : Promise.reject(response);
  }
  catch (error)
  {
    return Promise.reject(response);
  }
}