import React, { Fragment, useState } from 'react';
import { Breadcrumbs, H4, LI, P, UL } from '../../../AbstractElements';
import { Typeahead } from 'react-bootstrap-typeahead';
import DataTable from 'react-data-table-component'
import { Trash} from 'react-feather';
import { Container, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, CardBody,Form,Label,Input,Button } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import { useNavigate,useLocation } from "react-router-dom";
import {postRequest,getRequest,deleteRequest,putRequest} from "../../../Services/http-request-service.js"
  
const GenerateReport = (props) => {

  // Constants
  const { pathname } = useLocation();
  const navigate = useNavigate(); 
  const PATIENTNAME = getPatientName();

  // Table Data Constants
  const tableColumns = [
  {
    name: 'ICD Number',
    selector: row => row.id,
    filterable: true,
  },
  {
    name: 'ICD Code / Lab Value',
    selector: row => row.icd,
    filterable: true,
  },
  {
    name: 'Delete',
    selector: row => row.delete,
    filterable: true,
    cell:(row) => <Trash onClick={() => {deleteICD(row.id);}}/>,
  }
];


  const datafortables = [
  {
    id: '1',
    icd: 'B95.0 - Streptococcus, group A, as the cause of diseases classified elsewhere',
    delete: "",
  },
  {
    id: '2',
    icd: 'B95.1 - Streptococcus, group B, as the cause of diseases classified elsewhere',
    delete: "",
  },
  {
    id: '3',
    icd: 'B95.2 - Enterococcus as the cause of diseases classified elsewhere',
    delete: "",
  }
];

  // Type Ahead Data
  const icdTypeTyped = [
    {"name": "B95.0 - Streptococcus, group A, as the cause of diseases classified elsewhere"},
    {"name":"B95.1 - Streptococcus, group B, as the cause of diseases classified elsewhere"},
    {"name":"B95.2 - Enterococcus as the cause of diseases classified elsewhere"},
    {"name":"LOW - lab value"},
    {"name":"HIGH - lab value"},
  ];

  // Global Variables

  // useState Constants

  // modals
  const [modalAdd, setModalAdd] = useState(false);
  const toggleAdd = () => setModalAdd(!modalAdd);

  // forms
  const [icdType, setICDType] = useState([]);
  const [viewReportSettings, setViewReportSettings] = useState(false);
  const [printReportSettings, setPrintReportSettings] = useState(false);
  const [saveReportSettings, setSaveReportSettings] = useState(false);

  // Data Tables
  const [tableData, setTableData] = useState([]);  
  const [icdTableDataArray, setICDTableDataArray] = useState([]); 

  // Functions

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  function getPatientName()
  { 
    let data = pathname.replace(`${process.env.PUBLIC_URL}`,"");
    data = data.split("/");
    return data[3];
  }

  function viewPatient()
  {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default/viewPatient/${PATIENTNAME}/Dubai`);
  }

  async function addICD()
  { 
    icdTableDataArray.push({
      id: (icdTableDataArray.length+1).toString(),
      icd: icdType[0].name.includes("LOW - ") || icdType[0].name.includes("HIGH - ") ? icdType[0].name : icdType[0].name.substr(0,icdType[0].name.indexOf(" - ")),
      delete: "",
    });

    setICDTableDataArray(icdTableDataArray);
    setTableData([]);
    await new Promise(r => setTimeout(r, 50));
    setTableData(icdTableDataArray);
  }

  async function deleteICD(id)
  {
    // Variables
    var copyArray = [];
    let count;
    let idCount;

    // copy the array without the item to delete
    for (count = 0, idCount = 1; count < icdTableDataArray.length; count++)
    {
      if (count !== id-1)
      {
        copyArray.push({
          id: idCount,
          icd: icdTableDataArray[count].icd,
          delete: "",
        });
        idCount++;
      }
    }
    
    setICDTableDataArray(copyArray);
    setTableData([]);
    await new Promise(r => setTimeout(r, 50));
    setTableData(copyArray);
  }

  async function GenerateReportData()
  {
    // Constants
    const URL = "http://localhost:3000/v1/internal/authorized/patients/reports/generate/generate";

    // error check
    if (icdTableDataArray.length <= 0)
    {
      SweetAlert.fire({ title: "Error!", text: "At least 1 ICD is required", icon: "error" });
      return;
    } 

    // send the request
    try
    {
      let result = await postRequest(URL,icdTableDataArray);
      console.log(result);
    }
    catch (error)
    {
      SweetAlert.fire({ title: "Error!", text: "Could not generate the report", icon: "error" });
      return;
    }

    // open the report
    if (viewReportSettings)
    {
      openInNewTab("");
    }

    // print the report
    if (printReportSettings)
    {
      window.print();
    }

    // save to Charm Health
    if (saveReportSettings)
    {
      //
    }
  }

  // HTML
  return (
    <Fragment>
      <Breadcrumbs parent="Users" title="Generate Report" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card>
                                <CardHeader>
                                    <h5>{"ICD Codes and Lab Values"}</h5>
                                </CardHeader>
                                <CardBody>





                                <Button color="primary" onClick={viewPatient}>{"Back To View Patient"}</Button><br></br><br></br>
                                <Button color="primary" onClick={toggleAdd}>{"Add ICD"}</Button>
                      <Modal isOpen={modalAdd} toggle={toggleAdd} >
                          <ModalHeader toggle={toggleAdd}>{"Add ICD or Lab Value"}</ModalHeader>
                          <ModalBody>
                          
                                               

                      
                      ICD <Typeahead id="basic-typeahead" labelKey="name" multiple={false} options={icdTypeTyped} selected={icdType} onMenuToggle={() => {if (icdType.length > 0) {setICDType([])}}} onChange={(item) => setICDType(item)} placeholder="ICD"/><br></br>

                          </ModalBody>
                          <ModalFooter>
                          <Button color="primary" onClick={toggleAdd}>{"Close"}</Button>
                          <Button color="secondary" onClick={() => {toggleAdd(); addICD();}}>{"Add ICD"}</Button>
                          </ModalFooter>
                      </Modal>

                      <div><br></br>
                                            <DataTable
                  data={tableData}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  Clicked
                  pagination
                  paginationComponentOptions={{selectAllRowsItem: true,selectAllRowsItemText: "ALL"}}
                />
                      </div>







                     

                      <div className="checkbox checkbox-primary">
                              <Input id="inline-form-1" type="checkbox" checked={viewReportSettings} onChange={() => { setViewReportSettings(!viewReportSettings)}} />
                              <Label className="mb-0" htmlFor="inline-form-1"> {"View Report"}</Label>
                            </div>

                            <div className="checkbox checkbox-primary">
                              <Input id="inline-form-2" type="checkbox" checked={printReportSettings} onChange={() => { setPrintReportSettings(!printReportSettings)}} />
                              <Label className="mb-0" htmlFor="inline-form-2"> {"Print Report"}</Label>
                            </div>

                            <div className="checkbox checkbox-primary">
                              <Input id="inline-form-3" type="checkbox" checked={saveReportSettings} onChange={() => { setSaveReportSettings(!saveReportSettings)}} />
                              <Label className="mb-0" htmlFor="inline-form-3"> {"Save To Charm Health"}</Label>
                            </div>

                           <br></br> <Button color="primary" onClick={GenerateReportData}>{"Generate Report"}</Button>








                                    <div id="the-basics">
                                        <Form>
                                           
                 
                                          
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}

export default GenerateReport;