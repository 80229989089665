import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs, H4, H6, LI, P, UL } from '../../../AbstractElements';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from "react-datepicker";
import Chart from 'react-apexcharts';
import {radialChartLive } from '../../Common/Data/ApexChart';
import DataTable from 'react-data-table-component'
import { User, Clipboard, Trash} from 'react-feather';
import { Container, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, CardBody,Form,FormGroup,Label,Input,Button } from 'reactstrap'
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import {postRequest,getRequest,deleteRequest,putRequest} from "../../../Services/http-request-service.js"
import { CompareToLastMonth, TotalProject, TotalSale } from '../../../Constant';

const Dashboard = () => {
 // Constants
 const navigate = useNavigate(); 

 // Table Data Constants
 const tableColumns = [
 {
   name: 'Patient Number',
   selector: row => row.id,
   filterable: true,
 },
 {
   name: 'Name',
   selector: row => row.name,
   filterable: true,
 },  
 {
   name: 'Address',
   selector: row => row.address,
   filterable: true,
 },
 {
   name: 'Phone',
   selector: row => row.phone,
   filterable: true,
 },
 {
   name: 'Gender',
   selector: row => row.gender,
   filterable: true,
 },
 {
   name: 'Birth Date',
   selector: row => row.birthDate,
   filterable: true,
 },
 {
   name: 'Age',
   selector: row => row.age,
   filterable: true,
 },
 {
   name: 'Blood Type',
   selector: row => row.bloodType,
   filterable: true,
 },
 {
   name: 'View',
   selector: row => row.view,
   filterable: true,
   cell:(row) => <User onClick={() => {viewPatient(row.name);}}/>,
 },
 {
   name: 'Edit',
   selector: row => row.edit,
   filterable: true,
   cell:(row) => <Clipboard onClick={() => {editPatient(row);}}/>,
 },
 {
   name: 'Delete',
   selector: row => row.delete,
   filterable: true,
   cell:(row) => <Trash onClick={() => {deletePatient(row.name);}}/>,
 }
];


 const datafortables = [
 {
   id: '1',
   name: 'name',
   address: 'address',
   phone: 'phone',
   gender: 'male',
   birthDate: '01/02/1980',
   age: '100',
   bloodType: 'A+',
   view: "",
   edit: "",
   delete: "",
 },
 {
   id: '2',
   name: 'name',
   address: 'address',
   phone: 'phone',
   gender: 'male',
   birthDate: '01/02/1980',
   age: '100',
   bloodType: 'A+',
   view: "",
   edit: "",
   delete: "",
 },
 {
   id: '3',
   name: 'name',
   address: 'address',
   phone: 'phone',
   gender: 'male',
   birthDate: '01/02/1980',
   age: '100',
   bloodType: 'A+',
   view: "",
   edit: "",
   delete: "",
 }
];

 // Type Ahead Data
 const bloodTypeTyped = [
   {"name": "A+"},
   {"name":"A-"},
   {"name":"B+"},
   {"name":"B-"},
   {"name":"O+"},
   {"name":"O-"},
   {"name":"AB+"},
   {"name":"AB-"},
 ];

 // Global Variables

 // useState Constants

 // modals
 const [modalAdd, setModalAdd] = useState(false);
 const toggleAdd = () => setModalAdd(!modalAdd);
 const [modalEdit, setModalEdit] = useState(false);
 const toggleEdit = () => setModalEdit(!modalEdit);

 // forms
 const [name, setName] = useState("");
 const [address, setAddress] = useState("");
 const [phone, setPhone] = useState("");
 const [gender, setGender] = useState("male");
 const [birthDate, setBirthDate] = useState(new Date());
 const [bloodType, setBloodType] = useState([]);

 // stats
 const [usersCount, setUsersCount] = useState(0);
 const [reportsCount, setReportsCount] = useState(0);

 // Data Tables
 const [tableData, setTableData] = useState([]);

 // Functions

 // onload
 useEffect(() => {
   getPatients();
   getPatientsStats();
 }, []);

 function viewPatient(name)
 {
   navigate(`${process.env.PUBLIC_URL}/dashboard/default/viewPatient/${name}/Dubai`);
 }

 function editPatient(row)
 {
   // set the text boxes in the edit modal
   setName(row.name);
   setAddress(row.address);
   setPhone(row.phone);
   setGender(row.gender);
   setBirthDate(new Date(row.birthDate));
   setBloodType([{"name":row.bloodType}]);

   // open the edit modal
   toggleEdit();
 }

 async function deletePatient(name)
 {
   // Constants
   const URL = "http://localhost:3000/v1/internal/authorized/patients/delete";

   // Variables
   let data = {};

   // ask to confirm to delete the patient
   let userData = await SweetAlert.fire({ title: "Warning!", text: `Are you sure you want to delete patient ${name} ?`,showCancelButton: true,reverseButtons: true, icon: "warning",confirmButtonText: 'Yes, delete it!',cancelButtonText: 'No, cancel!', });

   if (!userData.isConfirmed)
   {
     return;
   }

   // build the delete request data
   data = {"name":name};

   // send the delete request
   try
   {
    setTableData([]);
     let result = await deleteRequest(URL,data);
     setTableData(result);
   }
   catch (error)
   {
     setTableData([]);
     SweetAlert.fire({ title: "Error!", text: "Could not delete the patient", icon: "error" });
     return;
   }
 }

 async function getPatients()
 {
   // Constants
   const URL = "http://localhost:3000/v1/internal/authorized/patients/get";

   // send the get request
   try
   {
    setTableData(datafortables);
     let result = await getRequest(URL);
     setTableData(result);
   }
   catch (error)
   {
     setTableData(datafortables);
     SweetAlert.fire({ title: "Error!", text: "Could not get the patients list", icon: "error" });
     return;
   }
 }

 async function getPatientsStats()
 {
   // Constants
   const URL = "http://localhost:3000/v1/internal/authorized/patients/stats";

   // send the get request
   try
   {
    setUsersCount(0);
     setReportsCount(0);
     let result = await getRequest(URL);
     setUsersCount(result.users);
     setUsersCount(result.reports);
   }
   catch (error)
   {
     setUsersCount(0);
     setReportsCount(0);
     SweetAlert.fire({ title: "Error!", text: "Could not get the patients stats", icon: "error" });
     return;
   }
 }

 async function addPatient()
 {
   // Constants
   const URL = "http://localhost:3000/v1/internal/authorized/patients/add";

   // Variables
   let data = {};
   let birthdatetext;
   let age;

   // error check universal
   if (name === "")
   {
     SweetAlert.fire({ title: "Error!", text: "All data is required", icon: "error" });
     return;
   }  

   SweetAlert.fire({ title: "Success!", text: "The Refill Request has been submitted successfully", icon: "success" });
 }

 async function editPatientSave()
 {
   // Constants
   const URL = "http://localhost:3000/v1/internal/authorized/patients/edit";

   // Variables
   let data = {};
   let birthdatetext;
   let age;

   // error check universal
   if (name === "" || address === "" || phone === "" || gender === "" || bloodType.length <= 0)
   {
     SweetAlert.fire({ title: "Error!", text: "All data is required", icon: "error" });
     return;
   }  

   // get the date
   birthdatetext = (birthDate.getUTCMonth() + 1) + "/" + birthDate.getUTCDate() + "/" + birthDate.getUTCFullYear();
   age = (new Date().getFullYear() - birthDate.getUTCFullYear()).toString();

   // build the post request data
   data = {"name":name,"address":address,"phone":phone,"gender":gender,"birthDate":birthdatetext,"age":age,"bloodType":bloodType[0].name};

   // send the post request
   try
   {
    setTableData([]);
     let result = await putRequest(URL,data);
     setTableData(result);
   }
   catch (error)
   {
     setTableData([]);
     SweetAlert.fire({ title: "Error!", text: "Could not edit the patient", icon: "error" });
     return;
   }
 }
 

 // HTML
 return (
   <Fragment>
     <Breadcrumbs parent="Users" title="Main Menu" />
     <Container fluid={true}>
       <div className="user-profile">
         <Row>
         <Col sm="12" xl="12" lg="12">
             <Card className="o-hidden">
               <CardBody className="bg-primary b-r-4">
                 <div className="media static-top-widget">
                   <div className="align-self-center text-center"><User /></div>
                   <div className="media-body"><h4 align="center" className="m-2">{"Welcome Walter"}</h4>
                    
                   </div>
                 </div>
               </CardBody>
             </Card>
           </Col>

           
           
           <Col sm="12">
             <Card>
                               <CardHeader>
                                   <h4>{"Medication"}</h4>
                               </CardHeader>
                               <CardBody>




                               <h5>{"Lisinopril"}</h5>
                               <img className="img-fluid for-light" src={require("../../../assets/images/logo/lisinopril.jpg")} alt="" />
                               <img className="img-fluid for-dark" src={require("../../../assets/images/logo/lisinopril.jpg")} alt="" />
                               <br></br>

                               <Button color="primary" onClick={toggleAdd}>{"Refill Request"}</Button>
                     <Modal isOpen={modalAdd} toggle={toggleAdd} >
                         <ModalHeader toggle={toggleAdd}>{"Confirm Refill Request"}</ModalHeader>
                         <ModalBody>
                         
                                              <FormGroup className="row">
                                              <Label className="col-sm-3 col-form-label" htmlFor="inputPassword3">{"Name"}</Label>
                                              <Col sm="12">
                                              <Input className="form-control" type="text" selected={name} onChange={(item) => setName(item.target.value)} placeholder="Name" />
                                              </Col>
                                              </FormGroup> 

                                              
                         </ModalBody>
                         <ModalFooter>
                         <Button color="primary" onClick={toggleAdd}>{"Close"}</Button>
                         <Button color="secondary" onClick={() => {toggleAdd(); addPatient();}}>{"Confirm Refill Request"}</Button>
                         </ModalFooter>
                     </Modal>







                    
                               </CardBody>
                           </Card>
                           <Card>
          <CardHeader>
            <Row>
              <Col xs='9'>
                <h5>{"Adherence"}</h5>
              </Col>
              <Col xs='3' className='text-end'>
              
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
          <h5>{"Lisinopril"}</h5>
            <div className='chart-container'>
              <div id='circlechart'>
                <Chart options={radialChartLive.options} series={radialChartLive.series} height='200' type='radialBar' />
              </div>
            </div>
          </CardBody>
        </Card>
           </Col>
         </Row>
       </div>
     </Container>
   </Fragment>
 );
};

export default Dashboard;
